.differentials-main{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 70px 0px;
    align-items: center;
}

.differentials-main h3{
    font-size: 50px;
    color: #fff;
    text-transform: uppercase;
    line-height: 50px;
    text-align: center;
    font-style: italic;
}

.differentials-main h3 span{
    color: #FF9E1B;
    font-weight: 700;
    font-family: "Oswald";
    display: block;
}

.differentials-content{
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    max-width: 1280px;
    width: 100%;
}

.differential-content-container{
    display: flex;
    justify-content: center;
    width: 100%;
}

.differential-content-holder{
    display: flex;
    flex-direction: column;
    gap: 15px;
    color: #fff;
    width: 300px;
}

.differential-content-holder h4{
    font-size: 18px;
    font-family: "Lato";
    font-weight: 700;
    text-transform: uppercase;
}

.differential-content-holder p{
    font-size: 16px;
    color: #B7B7B7;
    width: 250px;
}

/* Responsive Media Query */

@media screen and (max-width: 1080px) {
    .differentials-content{
        flex-direction: column;
    }
    .differential-content-holder{
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 768px) {
    
}
footer{
    width: 100%;
    background: #0f0f0f;
    padding: 50px 30px 20px 30px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    border-top: solid 3px #FF9E1B;
}

footer .link-list-content{
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    width: 100%;
    max-width: 1080px;
}

footer .link-list-holder{
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

footer .link-list-holder .icons-list{
    display: flex;
    flex-direction: row;
    gap: 15px;
}

footer .link-list-holder .icons-list li{
    display: flex;
    align-items: center;
}

footer .link-list-holder span{
    color: #fff;
    font-weight: 700;
    font-family: "Oswald";
    font-size: 20px;
    text-transform: uppercase;
    position: relative;
}

footer .link-list-holder span::before{
    content: "";
    width: 50px;
    height: 3px;
    background-color: #FF9E1B;
    position: absolute;
    bottom: -10px;
    left: 0;
}

footer .link-list-holder ul{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

footer .link-list-holder li{
    font-size: 14px;
    font-weight: 400;
    list-style: none;
    color: #b7b7b7;
}

footer .link-list-holder li a{
    font-size: 14px;
    font-weight: 400;
    list-style: none;
    color: #b7b7b7;
}

footer .link-list-holder li a:hover{
    text-decoration: underline;
}

footer .copyright{
    width: 100%;
    display: flex;
    color: #b7b7b7;
    padding: 15px 0px;
    justify-content: center;
}

footer .copyright-content{
    width: 100%;
    max-width: 1080px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    gap: 50px;
}

footer .copyright p{
    font-weight: 400;
    height: 25px;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    opacity: 0.5;
}

footer .copyright p span{
    display: block;
    font-size: 11px;
    color: #b7b7b7;
    text-transform: none;
    margin-top: 2px;
}

footer .logo-holder{
    max-width: 1080px;
    width: 100%;
    margin: 20px 0px;
}

footer .logo-holder img{
    width: 80px;
}

footer a{
    text-decoration: none;
}

footer .powered-by-khouse{
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-decoration: none;
}

footer .powered-by-khouse span{
    font-size: 12px;
    font-weight: 400;
    text-indent: -1px;
    color: #b7b7b7;
}

/* Responsive Media Query */

@media screen and (max-width: 768px) {
    /* footer .link-list-content{
        flex-direction: column;
    }
    footer .link-list-holder{
        align-items: center;
    }
    footer .link-list-holder ul{
        align-items: center;
    }
    footer .link-list-holder span::before{
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    } */
}
.admin-main{
    color: #fff;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    position: absolute;
    z-index: 10;
    background-color: #000;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin-main .login-box{
    max-width: 255px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
}

.admin-main .login-box h2{
    font-weight: 800;
    font-size: 33px;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-family: "Lato";
}

.admin-main .login-box input{
    width: 100%;
    height: 49px;
    border: solid 1px #222;
    background: none;
    padding: 0px 25px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
}

.admin-main .login-box button{
    width: 100%;
    height: 49px;
    background-color: transparent;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    margin-top: 20px;
    border: solid 1px #fff;
    cursor: pointer;
    transition: all 0.2s;
}

.admin-main .login-box button.await{
    background-color: #fff;
    color: 000;
}

.admin-main .login-box button:hover{
    color: #000;
    background-color: #fff;
}

.admin-main .login-box .error{
    position: absolute;
    bottom: -40px;
}
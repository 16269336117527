.values-main{
    width: 100%;
}

.values-main .values-content{
    width: 100%;
    height: 350px;
    position: relative;
}

.values-main .values-content:nth-child(1){
    background: url("../../assets/values/agilidade-bg.png");
    background-position: center;
    background-size: cover;
}

.values-main .values-content:nth-child(2){
    background: url("../../assets/values/parceria-bg.png");
    background-position: center;
    background-size: cover;
}

.values-main .values-content:nth-child(3){
    background: url("../../assets/values/campeoes-bg.png");
    background-position: center;
    background-size: cover;
}

.values-main .values-content:nth-child(2) h3{
    right: 0;
    left: auto;
}

.values-main .values-content h3{
    font-size: 70px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 350px;
    font-weight: 900;
    text-transform: uppercase;
    color: #fff;
    background-color: #ff9c1ba3;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 80px;
    font-style: italic;
}

@media screen and (max-width: 768px) {
    .values-main .values-content h3{
        width: 50%;
        font-size: 50px;
        line-height: 50px;
    }
}
.page-title{
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FF9E1B;
    background-position: center;
    background-size: cover;
}

.page-title h2{
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    font-size: 70px;
    animation-name: titleAnimation;
    animation-duration: 1s;
    animation-delay: 0.1s;
    position: relative;
    animation-fill-mode: forwards;
    opacity: 0;
    position: relative;
}

@keyframes titleAnimation {
    from {opacity: 0; font-size: 40px;}
    to {opacity: 1; font-size: 70px;}
}

/* Responsive Media Query */

@media screen and (max-width: 768px) {
    .page-title h2{
        font-size: 10vw !important;
    }
}
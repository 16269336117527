.modalitie-detail-main{
    width: 100%;
    background-color: #181818;
}

.modalitie-detail-text{
    display: flex;
    justify-content: center;
}

.modalitie-detail-main .page-title{
    background-position: center;
    background-size: cover;
}

.modalitie-detail-main.beachtennis .page-title{
    background-image: url("../../assets/modalities/beachtennis-bg.png");
}

.modalitie-detail-main.beachvolei .page-title{
    background-image: url("../../assets/modalities/beachvolei-bg.png");
}

.modalitie-detail-main.futevolei .page-title{
    background-image: url("../../assets/modalities/futevolei-bg.png");
}

.modalitie-detail-main.funcional .page-title{
    background-image: url("../../assets/modalities/funcional-bg.png");
}

.modalitie-detail-text p{
    max-width: 1000px;
    width: 100%;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    padding: 50px 16px;
    line-height: 24px;
}

.modalitie-detail-imgs{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    padding: 40px 0px;
}

.modalitie-detail-imgs div{
    width: 200px;
    height: 200px;
    border-radius: 200px;
    background-color: #e1e1e1;
}

.modalitie-detail-imgs div img{
    width: 100%;
    height: 100%;
}

.modalitie-units{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 40px 16px;
}

.modalitie-units p{
    font-size: 24px;
    text-transform: uppercase;
    font-family: "Oswald";
    font-weight: 700;
    color: #fff;
    font-style: italic;
}

.modalitie-units ul{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
}

.modalitie-units ul li{
    color: #fff;
    list-style: none;
    font-size: 18px;
    font-weight: 600;
}

.modalitie-units ul li a{
    color: #fff;
    text-decoration: none;
}

.modalitie-units ul li a:hover{
    color: #37BFE6;
    text-decoration: underline;
}
.riplay-store-call-main{
    width: 100%;
    height: 600px;
    background: url("../../assets/store/store-call-bg.png");
    background-size: cover;
    background-position: center;
    margin: 100px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 60px;
}

.riplay-store-call-main h3{
    font-size: 70px;
    text-transform: uppercase;
    color: #fff;
    line-height: 70px;
    text-align: center;
    text-shadow: #000 0px 0px 30px;
}

.riplay-store-call-main h3 span{
    display: block;
    font-family: "Oswald";
    font-weight: 700;
    color: #37BFE6;
}

/* Responsive Media Query */

@media screen and (max-width: 768px) {
    .riplay-store-call-main h3{
        font-size: 50px;
        line-height: 50px;
    }
}
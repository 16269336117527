.admpanel-categories-main{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 60px;
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    position: relative;
}

.admpanel-categories-main ul{
    width: 100%;
    max-width: 1080px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.admpanel-categories-main ul li{
    list-style: none;
    width: 100%;
    background-color: transparent;
    border: solid 1px rgba(255, 255, 255, 0.1);
    display: flex;
    padding: 20px 20px 20px 20px;
    display: flex;
    gap: 20px;
    position: relative;
    justify-content: space-between;
    align-items: center;
}

.admpanel-categories-main ul li p{
    font-size: 20px;
    font-weight: 500;
}

.admpanel-categories-main ul li span{
    font-size: 14px;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.admpanel-categories-main ul li span .listed{
    width: 20px;
    height: 20px;
    margin-top: 2px;
    border: solid 1px rgba(255, 255, 255, 0.2);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admpanel-categories-main ul li span .listed div{
    width: 12px;
    height: 12px;
    background-color: rgb(59, 121, 255);
    border-radius: 20px;
}

.admpanel-categories-main .button-holder{
    width: 100%;
    max-width: 1080px;
    display: flex;
    justify-content: flex-end;
}

.admpanel-categories-main .button-holder button{
    width: 255px;
    height: 49px;
    background-color: #fff;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.about-page-main{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-page-main .page-title{
    background-image: url("../../assets/about/about-title.png");
}

.about-page-main .text-box{
    padding: 40px 30px;
    color: #fff;
    max-width: 1280px;
}

.about-page-main .text-box h3{
    font-size: 50px;
    text-transform: uppercase;
    width: 100%;
    font-style: italic;
}

.about-page-main .text-box p{
    font-size: 20px;
    margin-top: 20px;
    line-height: 30px;
    color: #b7b7b7;
}

.about-page-main .full-image{
    width: 100%;
}

.about-page-main .image-text-box{
    color: #fff;
    max-width: 1280px;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    position: relative;
    margin-bottom: 90px;
    gap: 40px;
}

.about-page-main .image-text-box > img{
    width: 40%;
}

.about-page-main .image-text-box .image-text-box-content{
    width: 60%;
    display: flex;
    height: 100%;
    flex-direction: column;
}

.about-page-main .image-text-box .image-text-box-content p{
    line-height: 30px;
}

.about-page-main .image-text-box .image-text-box-content img{
    margin-bottom: 30px;
    width: 200px;
}

/* Responsive Media Query */

@media screen and (max-width: 1080px) {

}

@media screen and (max-width: 768px) {
    .about-page-main .image-text-box > img{
        display: none;
    }
    .about-page-main .image-text-box .image-text-box-content{
        width: 100%;
        border-top: solid 1px rgb(77, 77, 77);
        padding-top: 80px;
    }
    .about-page-main .image-text-box .image-text-box-content img{
        width: 160px;
        margin-bottom: 10px;
    }
    .about-page-main .image-text-box .image-text-box-content p{
        font-size: 18px;
        line-height: 28px;
    }
    .about-page-main .text-box p{
        font-size: 18px;
        line-height: 28px;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');

.post-detail-main{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* .post-detail-main .post-image{
    width: 100%;
    height: 525px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
} */

.post-detail-main .post-image{
    width: 100%;
    height: 525px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    animation-name: animaImage;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
}

@keyframes animaImage {
    0%{height: 100vh;}
    100%{height: 525px;}
}

.post-detail-main .post-detail-wrap{
    width: 100%;
    max-width: 1080px;
    display: flex;
    gap: 35px;
    padding: 70px 30px;
}

.post-detail-main .post-detail-wrap .post-content{
    width: 100%;
}

.post-detail-main .post-detail-wrap .post-content .post-title{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.post-detail-main .post-detail-wrap .post-content .post-title a{
    text-decoration: none;
}

.post-detail-main .post-detail-wrap .post-content .post-title h2{
    font-size: 20px;
    color: #ff9e1b;
    text-transform: uppercase;
    font-weight: 500;
}

.post-detail-main .post-detail-wrap .post-content .post-title h1{
    font-size: 40px;
    color: #fff;
    text-transform: uppercase;
    max-width: 350px;
    font-weight: 500;
    line-height: 45px;
}

.post-detail-main .post-detail-wrap .post-content .post-title span{
    font-size: 14px;
    color: #999;
    font-weight: 400;
    margin-top: 20px;
}

.post-detail-main .post-detail-wrap .post-content p{
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    color: #e1e1e1;
    margin-top: 70px;
    /* text-indent: 20px; */
    font-family: "Open Sans";
    letter-spacing: 0.2px;
}

.post-detail-main .post-detail-wrap .post-content img{
    width: 100%;
}

.post-detail-main .post-detail-wrap .post-content p a{
    color: #37BFE6;
    text-decoration: none;
    font-weight: 600;
    display: flex;
    line-height: 20px;
    flex-direction: column;
    border-left: solid 2px #fff;
    padding: 10px 30px;
}

.post-detail-main .post-detail-wrap .post-content p a:hover{
    border-left: solid 2px #37BFE6;
}

.post-detail-main .post-detail-wrap .post-content p a span{
    font-size: 14px;
    color: #999;
    font-weight: 400;
}

/* Post Author */

.post-detail-main .post-detail-wrap .author-box{
    min-width: 330px;
    max-width: 330px;
    border: solid 1px #505050;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
}

.post-detail-main .post-detail-wrap .author-box .author-infos{
    display: flex;
    gap: 15px;
    align-items: center;
}

.post-detail-main .post-detail-wrap .author-box .author-infos img{
    width: 50px;
    max-height: 50px;
    border-radius: 50px;
}

.post-detail-main .post-detail-wrap .author-box .author-infos p{
    display: flex;
    flex-direction: column;
    font-size: 13px;
    color: #999;
    font-weight: 400;
}

.post-detail-main .post-detail-wrap .author-box .author-infos p span{
    color: #fff;
    font-weight: 600;
    font-size: 14px;
}

.post-detail-main .post-detail-wrap .author-box > p{
    font-size: 14px;
    color: #fff;
    line-height: 20px;
    padding-left: 65px;
    color: #999;
}

@media screen and (max-width: 1080px) {
    .post-detail-main .post-detail-wrap{
        flex-direction: column;
    }

    .post-detail-main .post-detail-wrap .author-box{
        min-width: 100%;
        margin-top: 50px;
    }
}
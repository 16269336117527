/* Lato Font Import */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

body{
	background-color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6,
figure,
fieldset,
img,
p {
    margin: 0;
    padding: 0;
    border: 0;
}
* {
    margin: 0;
    padding: 0px;
    border: 0;
    box-sizing: border-box;
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    font-weight: 300;
    font-family: "Lato", sans-serif;
}

body{
  margin: 0;
  padding: 0;
  font-family: "Lato";
}

h1,h2,h3,h4,h5,h6{
  font-family: "Oswald", "Lato", sans-serif;
  font-weight: 700;
}
.modalities-cards-main{
    width: 100%;
    position: relative;
    padding: 60px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalities-cards-main ul{
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.modalities-cards-main ul li{
    width: 25%;
    list-style: none;
    height: 500px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 60px;
    padding-left: 20px;
    line-height: 75px;
    transition: width 0.5s;
    cursor: pointer;
    font-family: "Oswald";
    font-style: italic;
}

.modalities-cards-main ul li:hover{
    width: 50%;
    list-style: none;
}

.modalities-cards-main ul li:nth-child(1){
    background-image: url("../../assets/modalities/beachtennis-bg.png");
}

.modalities-cards-main ul li:nth-child(2){
    background-image: url("../../assets/modalities/futevolei-bg.png");
}

.modalities-cards-main ul li:nth-child(3){
    background-image: url("../../assets/modalities/beachvolei-bg.png");
}

.modalities-cards-main ul li:nth-child(4){
    background-image: url("../../assets/modalities/funcional-bg.png");
}

/* Responsive Media Query */
@media screen and (max-width: 768px) {
    .modalities-cards-main ul{
        flex-wrap: wrap;
    }
    .modalities-cards-main ul li{
        width: 50%;
        height: 350px;
        font-size: 45px;
        line-height: 60px;
    }
    .modalities-cards-main ul li:hover{
        width: 50%;
    }
}
.our-units-call-main{
    padding: 100px 30px 200px 30px;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.our-units-call-container{
    width: 1280px;
    height: 100%;
    position: relative;
}

.our-units-call-main .our-units-call-img{
    width: 600px;
}

.our-units-call-main .circle-shape{
    width: 600px;
    height: 600px;
    background-color: #FF9E1B;
    position: absolute;
    z-index: -1;
    border-radius: 600px;
    left: -250px;
    bottom: -90px;
}

.our-units-call-main .our-units-call-content{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 30px;
}

.our-units-call-main .our-units-call-content h3{
    font-size: 70px;
    color: #fff;
    font-weight: 900;
    text-align: right;
    line-height: 80px;
}

.our-units-call-main .our-units-call-content h3 span{
    font-weight: 900;
    font-family: "Oswald";
    color: #37BFE6;
}

.our-units-call-main .our-units-call-content .link-holder{
    display: flex;
    justify-content: right;
    margin-top: 60px;
}

.our-units-call-main .our-units-call-content .link-holder.mobile{
    display: none;
}

/* Responsive Media Query */

@media screen and (max-width: 1080px) {
    .our-units-call-main{
        padding: 100px 16px 200px 16px;
    }
    .our-units-call-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .our-units-call-main .our-units-call-img{
        width: 500px;
        margin-top: 100px;
    }
    .our-units-call-main .circle-shape{
        width: 500px;
        height: 500px;
        left: auto;
        margin-left: -300px;
    }
    .our-units-call-main .our-units-call-content{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-right: 0;
    }
    .our-units-call-main .our-units-call-content h3{
        text-align: center;
    }
    .our-units-call-main .our-units-call-content .link-holder{
        display: none;
    }
    .our-units-call-main .our-units-call-content .link-holder.mobile{
        display: flex;
    }
}

@media screen and (max-width: 768px) {
    .our-units-call-main .our-units-call-content h3{
        font-size: 32px;
        line-height: 37px;
    }
    .our-units-call-main .our-units-call-img{
        width: 400px;
        margin-top: 100px;
    }
    .our-units-call-main .circle-shape{
        width: 400px;
        height: 400px;
        left: auto;
        margin-left: -300px;
    }
}
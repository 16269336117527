.latest-category-main{
    color: #fff;
    padding-top: 100px;
    display: flex;
    justify-content: center;
}

.latest-category-main .blog-category-page-main-wrapper{
    display: flex;
    flex-direction: column;
    max-width: 1080px;
    width: 100%;
}

.latest-category-main h1{
    font-size: 90px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    line-height: 100px;
}

.latest-category-main h1 span{
    font-family: "Oswald";
    font-weight: 600;
    color: #FF9E1B;
}

.latest-category-main ul{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.blog-category-page-main{
    color: #fff;
    padding-top: 100px;
    display: flex;
    justify-content: center;
}

.blog-category-page-main .blog-category-page-main-wrapper{
    display: flex;
    flex-direction: column;
    max-width: 1080px;
    width: 100%;
    padding: 0px 30px;
}

.blog-category-page-main h2.section-title{
    font-size: 90px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    display: flex;
    gap: 20px;
}

.blog-category-page-main h2.section-title .section{
    font-family: "Oswald";
    font-weight: 600;
    color: #FF9E1B;
}

.blog-category-page-main h2.section-title .caption{
    font-family: "Oswald";
    font-weight: 600;
    color: #fff;
    font-size: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 40px;
}

.blog-category-page-main .blog-category-page-main-wrapper ul{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

@media screen and (max-width: 700px) {
    .blog-category-page-main h2.section-title{
        font-size: 14vw;
    }
    .blog-category-page-main h2.section-title .caption{
        display: none;
    }
}

/* Responsive Media Query */
@media screen and (max-width: 1080px) {
    .blog-search-main .blog-search-wrapper ul{
        justify-content: center;
    }
    .blog-category-page-main h2.section-title{
        width: 100%;
        justify-content: center;
    }
    .blog-category-page-main .blog-category-page-main-wrapper ul{
        justify-content: center;
    }
}
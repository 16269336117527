.franchise-banner{
    width: 100%;
    min-height: 400px;
    background: url("../../assets/franchise/franchise-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    padding: 40px 50px;
}

.franchise-banner .franchise-banner-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    max-width: 1080px;
}

.franchise-banner-title{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.franchise-banner-title h2{
    font-family: "Lato";
    font-weight: 300;
    color: #fff;
    line-height: 30px;
    font-size: 25px;
}

.franchise-banner-title h2 span{
    display: block;
    font-weight: 700;
}

.franchise-banner-title > span{
    color: #fff;
    font-size: 14px;
}

.be-franchise-form{
    background-color: #000;
    width: 400px;
    border-top: solid 5px #ff9e1b;
    border-radius: 10px;
    padding: 30px 20px 40px 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
}

.be-franchise-form.highlight{
    animation-name: highlight;
    animation-duration: 4s;
    animation-delay: 1s;
}

@keyframes highlight {
    0% {box-shadow: rgba(255, 255, 255, 0) 0px 0px 60px;}
    50% {box-shadow: #ff9e1b 0px 0px 60px;}
    100% {box-shadow: rgba(255, 255, 255, 0) 0px 0px 60px;}
  }

.be-franchise-form .franchise-form-title{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.be-franchise-form .franchise-form-title h2{
    font-family: "Lato";
    color: #fff;
    font-size: 20px;
    text-align: center;
}

.be-franchise-form .franchise-form-title p{
    color: #b7b7b7;
    font-size: 12px;
    text-align: center;
    line-height: 16px;
}

.be-franchise-form form{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 15px;
}

.be-franchise-form label{
    display: flex;
    flex-direction: column;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    gap: 5px;
    position: relative;
}

.be-franchise-form label input{
    background: transparent;
    border: solid 1px #2b2b2b;
    padding: 8px 20px;
    border-radius: 10px;
    color: #fff;
    font-size: 12px;
    background-color: #080808;
}

.be-franchise-form label select{
    background: transparent;
    border: solid 1px #2b2b2b;
    padding: 8px 32px;
    border-radius: 10px;
    color: #fff;
    font-size: 12px;
    background-color: #080808;
}

.be-franchise-form label .money-indicator{
    position: absolute;
    bottom: 9px;
    left: 15px;
    font-weight: 400;
    opacity: 0.4;
}

.be-franchise-form label.input-money input{
    text-indent: 15px;
}

.be-franchise-form form > button{
    padding: 10px 38px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    background: #ff9e1b;
    border-radius: 10px;
    width: 100%;
    margin-top: 20px;
    cursor: pointer;
    border: solid 1px #ff9e1b;
}

.be-franchise-form form > button:hover{
    background-color: #000;
    border: solid 1px #ff9e1b;
    color: #ff9e1b;
}

.franchise-main .email-success{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    left: 0;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.franchise-main .email-success span{
    color: #fff;
    font-weight: 500;
}

.riplay-numbers{
    padding: 60px 50px;
    display: flex;
    flex-direction: column;
    gap: 70px;
}

.riplay-numbers .riplay-numbers-title h2{
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: 50px;
    font-weight: 700;
    line-height: 60px;
    font-style: italic;
}

.riplay-numbers .riplay-numbers-title h2 span{
    font-family: "Oswald";
    font-weight: 700;
    display: block;
    color: #37BFE6;
    font-size: 50px;
}

.riplay-numbers .riplay-numbers-content{
    width: 100%;
    display: flex;
    justify-content: center;
}

.riplay-numbers .riplay-numbers-content ul{
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
}

.riplay-numbers .riplay-numbers-content li{
    display: flex;
    flex-direction: column;
    width: 200px;
    border: solid 1px #777777;
    padding: 20px 20px;
    justify-content: center;
    transition: transform 0.1s;
}

.riplay-numbers .riplay-numbers-content li:hover{
    transform: scale3d(1.3,1.3,5);
    border: solid 1px #37BFE6;
}

.riplay-numbers .riplay-numbers-content li span{
    color: #fff;
}

.riplay-numbers .riplay-numbers-content li span:nth-child(1){
    font-size: 40px;
    font-weight: 900;
    color: #37BFE6;
}

.riplay-numbers .riplay-numbers-content li span:nth-child(2){
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
}

.riplay-numbers .riplay-numbers-content li span:nth-child(3){
    font-size: 14px;
    color: #b7b7b7;
}

.cta-franchise-btn{
    display: flex;
    justify-content: center;
}

.cta-franchise-btn button{
    padding: 10px 38px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    background: #ff9e1b;
    border: solid 1px #ff9e1b;
    color: #000;
    border-radius: 10px;
    cursor: pointer;
}

.cta-franchise-btn button:hover{
    background: #000;
    color: #ff9e1b;
}

.franchise-text-box{
    display: flex;
    flex-direction: column;
    color: #fff;
    gap: 30px;
    align-items: center;
    padding: 50px 30px;
}

.franchise-text-box h2{
    font-size: 50px;
    text-transform: uppercase;
    text-align: center;
    line-height: 50px;
    font-style: italic;
}

.franchise-text-box h2 span{
    font-weight: 700;
    font-family: "Oswald";
    color: #37BFE6;
}

.franchise-text-box p{
    font-size: 16px;
    line-height: 24px;
    max-width: 1080px;
    color: #b7b7b7;
    text-align: center;
}

/* Responsive Media Query */

@media screen and (max-width: 1080px) {
    
}

@media screen and (max-width: 840px) {
    .franchise-banner .franchise-banner-container{
        flex-direction: column;
        margin-top: 40px;
        gap: 40px;
    }
}

@media screen and (max-width: 500px) {
    .franchise-banner{
        padding: 40px 16px;
    }
    .be-franchise-form{
        width: 100%;
    }
    .be-franchise-form form{
        flex-direction: column;
        justify-content: baseline;
    }
    .franchise-text-box h2{
        font-size: 40px;
        line-height: 50px;
    }
    .franchise-text-box h2 span{
        display: block;
    }
    .franchise-text-box p{
        font-size: 14px;
        line-height: 20px;
    }
}
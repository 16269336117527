.modalities-main{

}

.modalities-main .page-title{
    background-image: url("../../assets/modalities/modalities-title-bg.png");
    background-position: center;
    background-size: cover;
}

.modalities-main .modalities-cards-main a.cta-link{
    display: none;
}

.modalities-desc{
    color: #b7b7b7;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalities-desc p{
    max-width: 800px;
    width: 100%;
    text-align: center;
    padding: 50px 16px 0px 16px;
    line-height: 25px;
}

/* Titled List */
.titled-list-holder{
    display: flex;
    justify-content: center;
}

.titled-list-holder-content{
    display: flex;
    max-width: 1080px;
    width: 100%;
}

.modalities-main .titled-list{
    width: 100%;
    padding: 70px 16px;
}

.modalities-main .titled-list h3{
    width: 100%;
    font-size: 50px;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    font-style: italic;
    line-height: 50px;
}

.modalities-main .titled-list h3 span{
    font-weight: 700;
    font-family: "Oswald";
    color: #37BFE6;
    display: block;
}

.modalities-main .titled-list p{
    width: 100%;
    font-size: 14px;
    text-align: center;
    color: #b7b7b7;
    line-height: 18px;
    margin-top: 30px;
}

.modalities-main .titled-list .titled-list-content{
    display: flex;
    color: #b7b7b7;
    justify-content: center;
}

.modalities-main .titled-list .titled-list-content ul{
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 400px;
    margin-top: 30px;
    width: 100%;
}

.modalities-main .titled-list .titled-list-content ul li{
    list-style: none;
    position: relative;
    padding-left: 30px;
}

.modalities-main .titled-list .titled-list-content ul li::before{
    content: "";
    position: absolute;
    width: 20px;
    height: 2px;
    background-color: #37BFE6;
    left: 0;
    top: 10px;
}

/* Ambassadors */

.ambassadors{
    padding: 70px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ambassadors h3{
    color: #fff;
    font-size: 50px;
    text-transform: uppercase;
    text-align: center;
    line-height: 50px;
    font-style: italic;
}

.ambassadors h3 span{
    color: #37BFE6;
    font-weight: 700;
    font-family: "Oswald";
}

.ambassadors ul{
    display: flex;
    margin-top: 70px;
    gap: 40px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.ambassadors ul li{
    color: #fff;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ambassadors ul li img{
    width: 210px;
    margin-bottom: 20px;
}

.ambassadors ul li p{
    font-size: 16px;
    font-weight: 600;
}

.ambassadors ul li span{
    font-size: 16px;
    font-weight: 300;
    color: #b7b7b7;
}

.ambassadors ul li a{
    font-size: 16px;
    font-weight: 300;
    color: #b7b7b7;
    text-decoration: none;
}

.ambassadors ul li a:hover{
    color: #37BFE6;
    font-weight: 500;
}

/* Responsive Media Query */

@media screen and (max-width: 1080px) {
    .titled-list-holder-content{
        flex-direction: column;
    }
}
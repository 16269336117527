.contact-us-call-main{
    width: 100%;
    height: 600px;
    background: url("../../assets/contactUs/contact-call-bg.png");
    background-position: center;
    background-size: cover;
    padding: 60px 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.contact-us-call-main h3{
    font-size: 70px;
    text-transform: uppercase;
    font-style: italic;
    line-height: 70px;
    color: #fff;
    margin-bottom: 50px;
}

.contact-us-call-main h3 span{
    font-weight: 700;
    font-family: "Oswald";
    display: block;
    color: #37BFE6;
}

/* Responsive Media Query */

@media screen and (max-width: 768px) {
    .contact-us-call-main{
        justify-content: center;
    }
    .contact-us-call-main h3{
        text-shadow: #000 0px 0px 40px;
    }
}
.franchiseform-main{
    width: 100%;
    padding: 70px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.franchiseform-main h2{
    color: #fff;
    font-size: 50px;
    font-style: italic;
    text-transform: uppercase;
    line-height: 50px;
    text-align: center;
}

.franchiseform-main h2 span{
    color: #37BFE6;
    font-family: "Oswald";
    font-weight: 700;
    font-style: italic;
    display: block;
}

.franchiseform-main p{
    color: #b7b7b7;
    font-size: 18px;
    text-align: center;
    max-width: 400px;
    margin-top: 30px;
}

.franchiseform-main form{
    margin-top: 50px;
    display: flex;
    max-width: 430px;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 25px;
}

.franchiseform-main form label{
    display: flex;
    flex-direction: column;
    width: 200px;
    color: #fff;
    gap: 10px;
}

.franchiseform-main form label span{
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    text-indent: 15px;
}

.franchiseform-main form label input{
    background: none;
    border: solid 1px #333;
    border-radius: 10px;
    color: #b7b7b7;
    padding: 8px 15px;
}

.franchiseform-main form button{
    width: 100%;
    height: 40px;
    background: #37BFE6;
    border: solid 2px #37BFE6;
    border-radius: 10px;
    color: #000;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 30px;
    cursor: pointer;
}

.franchiseform-main form button:hover{
    background: transparent;
    color: #37BFE6;
}

@media screen and (max-width: 768px) {
    .franchiseform-main form{
        justify-content: center;
    }
}
.aboutcall-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    padding: 100px 0px;
}

.aboutcall-main h2{
    font-size: 70px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 900;
    font-style: normal;
}

.aboutcall-main h2 span{
    font-weight: 700;
    font-family: "Oswald";
    color: #37bfe6;
}

.aboutcall-main p{
    font-size: 20px;
    font-weight: 300;
    width: 700px;
    text-align: center;
    margin-top: 10px;
    color: #b7b7b7;
    z-index: 1;
}

.aboutcall-main .imageBackground{
    width: 100%;
    height: 700px;
    background: url("../../assets/about/about-image.png");
    background-position: center;
    background-size: cover;
    margin-top: -40px;
}

/* Responsive Media Query */

@media screen and (max-width: 1080px) {
    .aboutcall-main h2{
        font-size: 50px;
    }
}

@media screen and (max-width: 768px) {
    .aboutcall-main h2{
        font-size: 40px;
        text-align: left;
        line-height: 40px;
        width: 100%;
        padding: 0px 16px;
    }
    .aboutcall-main p{
        width: 100%;
        font-size: 18px;
        margin-top: 20px;
        padding: 0px 100px 0px 16px;
        text-align: left;
    }
    .aboutcall-main h2 span{
        display: block;
    }
}
.admpanel-posts-main{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 60px;
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    position: relative;
}

.admpanel-posts-main ul{
    width: 100%;
    max-width: 1080px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.admpanel-posts-main ul li{
    list-style: none;
    width: 100%;
    height: 70px;
    background-color: transparent;
    border: solid 1px rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    position: relative;
}

.admpanel-posts-main ul li:hover button{
    display: block;
}

.admpanel-posts-main ul li button{
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 0;
    background-color: rgb(234, 67, 67);
    display: none;
    cursor: pointer;
    font-weight: 800;
}

.admpanel-posts-main ul li a{
    color: #fff;
    text-decoration: none;
}

.admpanel-posts-main ul li .title h4{
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
}

.admpanel-posts-main ul li .title span{
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    font-family: "Oswald";
    color: #FF9E1B;
}

.admpanel-posts-main ul li .author{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1px;
}

.admpanel-posts-main ul li .author .date{
    font-size: 12px;
    color: #999999;
    font-weight: 400;
}

.admpanel-posts-main ul li .author .authorName{
    font-size: 14px;
    color: #fff;
    font-weight: 400;
}

.admpanel-posts-main .button-holder{
    width: 100%;
    max-width: 1080px;
    display: flex;
    justify-content: flex-end;
}

.admpanel-posts-main .button-holder button{
    width: 255px;
    height: 49px;
    background-color: #fff;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

/* New Post */
.newPostMain{
    width: 100%;
    min-height: 100%;
    background-color: #000;
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
}

.newPostMain .newPostMainFlex{
    width: 100%;
    max-width: 1080px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.newPostMain .newPostMainFlex input{
    width: 100%;
    height: 50px;
    border: solid 1px #333;
    background-color: transparent;
    color: #fff;
    padding: 0px 25px;
    font-size: 16px;
    font-weight: 400;
}

.newPostMain .newPostMainFlex select{
    width: 100%;
    height: 50px;
    border: solid 1px #333;
    background-color: transparent;
    color: #fff;
    padding: 0px 25px;
    font-size: 16px;
    font-weight: 400;
    position: relative;
}

.newPostMain .newPostMainFlex .create-paragraphs{
    display: flex;
    align-items: center;
    background-color: rgb(20, 20, 20);
    padding: 10px 30px;
    font-size: 14px;
    gap: 5px;
    justify-content: center;
}

.newPostMain .newPostMainFlex textarea{
    width: 100%;
    height: 200px;
    border: solid 1px #333;
    background-color: transparent;
    padding: 20px 25px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    resize: none;
}

.newPostMain .newPostMainFlex .button-holder-left{
    width: 100%;
    max-width: 1080px;
    display: flex;
    justify-content: flex-start;
}

.newPostMain .newPostMainFlex .button-holder-left button{
    width: 255px;
    height: 49px;
    background-color: transparent;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    border: solid 1px #fff;
}
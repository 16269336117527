.units-main{

}

.units-main .page-title{
    background-image: url("../../assets/ourUnits/units-title-bg.png");
}



/* All units */
.units-main .all-units{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.units-main .all-units .all-units-topbar{
    width: 100%;
    padding: 50px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.units-main .all-units .all-units-topbar h3{
    color: #fff;
    text-transform: uppercase;
    font-size: 40px;
    text-align: center;
}

.units-main .all-units .all-units-topbar .filter-title{
    font-size: 14px;
    text-transform: uppercase;
    color: #7F7F7F;
    letter-spacing: 3px;
    font-weight: 400;
}

.units-main .all-units .all-units-topbar .select-box{
    width: 300px;
    background-color: #000;
    border: solid 1px #3e3e3e;
    border-radius: 10px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.units-main .all-units .all-units-topbar .select-box:hover{
    border: solid 1px #fff;
}

.units-main .all-units .all-units-topbar .select-box .select-box-topbar{
    display: flex;
    flex-direction: column;
    gap: 1px;
    position: relative;
}

.units-main .all-units .all-units-topbar .select-box .select-box-topbar::before{
    content: "";
    position: absolute;
    right: 0;
    top: 8px;
    width: 15px;
    height: 18px;
    background-image: url("../../assets/icons/arrow-right.png");
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.2s;
}

.units-main .all-units .all-units-topbar .select-box .select-box-topbar.active::before{
    transform: rotate(90deg);
}

.units-main .all-units .all-units-topbar .select-box .select-box-title{
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    color: #7F7F7F;
}

.units-main .all-units .all-units-topbar .filter-holder{
    display: flex;
    gap: 20px;
}

.units-main .all-units .all-units-topbar .select-box .selected{
    font-size: 15px;
    text-transform: normal;
    font-weight: 400;
    color: #37BFE6;
}

.units-main .all-units .all-units-topbar .select-box ul{
    width: 100%;
    padding: 30px 0px;
    display: flex;
    flex-direction: column;
}

.units-main .all-units .all-units-topbar .select-box ul li{
    list-style: none;
    color: #fff;
    font-weight: 400;
    cursor: pointer;
    padding: 5px 0px;
}

.units-main .all-units .all-units-topbar .select-box ul li:hover{
    color: #37BFE6;
}

.units-main .all-units .all-units-container{
    width: 100%;
    padding: 50px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.units-main .all-units .all-units-container ul{
    display: flex;
    flex-wrap: wrap;
    max-width: 1080px;
    width: 100%;
    justify-content: center;
    gap: 15px;
}

.units-main .all-units .all-units-container li{
    width: 350px;
    height: 470px;
    border: solid 1px #1f1f1f;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
}

.units-main .all-units .all-units-container li .unidade-image{
    width: 100%;
    background-color: #1f1f1f;
    overflow: hidden;
    cursor: ew-resize;
    min-height: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #b7b7b7;
}

.units-main .all-units .all-units-container li img{
    width: 100%;
    height: 100%;
}

.units-main .all-units .all-units-container li .unidade-content{
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
}

.units-main .all-units .all-units-container li .unidade-content h6{
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 10px;
    font-family: "Lato";
    font-weight: 600;
    color: #fff;
}

.units-main .all-units .all-units-container li .unidade-content span{
    font-size: 14px;
    color: #b7b7b7;
}

.units-main .all-units .all-units-container li .unidade-content .unidade-available-modalities{
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.units-main .all-units .all-units-container li .unidade-content .unidade-available-modalities .mod-available{
    color: #FF9E1B;
    border: solid 2px #FF9E1B;
    padding: 3px 10px;
    text-transform: uppercase;
    font-size: 10px;
    border-radius: 20px;
    font-weight: 800;
}

.units-main .all-units .all-units-container li .unit-whatsapp{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    background-color: #000;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 13px;
    cursor: pointer;
    text-align: center;
    line-height: 40px;
    text-decoration: none;
    color: #5b5b5b;
    border-top: solid 1px #292929;
    transition: all 0.3s;
}

.units-main .all-units .all-units-container li:hover .unit-whatsapp{
    background-color: #25d366;
    color: #000;
}

.units-main .all-units .all-units-container li .unit-whatsapp:hover{
    text-decoration: underline;
}

.not-found{
    color: #b7b7b7;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    width: 100%;
}

.not-found button{
    padding: 10px 18px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 100px;
    max-width: 220px;
    background-color: #37BFE6;
    border: solid 1px #37BFE6;
    cursor: pointer;
}

.not-found button:hover{
    background-color: #000;
    color: #37BFE6;
}

.swiper-button-next, .swiper-button-prev{
    background-color: transparent; 
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1;
    cursor: pointer;
}

.swiper-button-next{
    right: 0;
    background-image: url("../../assets/icons/b-arrow-right.png");
    background-size: 15px;
    background-position: center;
    background-repeat: no-repeat;
}

.swiper-button-prev{
    left: 0;
    background-image: url("../../assets/icons/b-arrow-right.png");
    background-size: 15px;
    background-position: center;
    background-repeat: no-repeat;
    transform: rotate(180deg);
}

.swiper-button-disabled{
    opacity: 0.3;
}

.swiper{
    height: 230px;
}

/* Responsive Media Query */

@media screen and (max-width: 1080px) {

}

@media screen and (max-width: 768px) {
    .units-main .all-units .all-units-topbar .filter-holder{
        flex-direction: column;
    }
}
.cta-link{
    text-transform: uppercase;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    letter-spacing: 2px;
    position: relative;
}

.cta-link.right::before{
    left: auto;
    right: 0;
}

.cta-link.center::before{
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.cta-link::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: -15px;
    width: 20%;
    height: 5px;
    background-color: #ff9c1b;
    transition: all 0.4s;
}

.cta-link:hover::before{
    width: 80%;
}


@media screen and (max-width: 768px) {
    .cta-link{
        font-size: 14px;
    }

    .cta-link::before{
        bottom: -13px;
    }
}
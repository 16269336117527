.welcome-main{
    width: 100%;
    height: calc(100vh - 65px);
    background: #000;
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.welcome-main .riplay-logo{
    width: 400px;
    position: absolute;
    animation-name: logoAnimation;
    animation-duration: 0.5s;
    animation-delay: 0.1s;
    position: relative;
    animation-fill-mode: forwards;
    top: -100px;
    opacity: 0;
}

@keyframes logoAnimation {
    from {top: 100px; opacity: 0;}
    to {top: 0px; opacity: 1;}
}

.welcome-main video.video-background{
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    object-fit: fill;
    object-position: center;
}

.opacity-mask{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4;
}

/* Scrolldown Button */
.btn-scroll-down-holder{
    width: 100%;
    position: absolute;
    bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    position: relative;
    width: 28px;
}

.chevron {
    position: absolute;
    width: 28px;
    height: 8px;
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
    animation: move 3s ease-out infinite;
}

.chevron:first-child {
    animation: move 3s ease-out 1s infinite;    
}

.chevron:nth-child(2) {
    animation: move 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
    content: ' ';
    position: absolute;
    top: 0;
    height: 100%;
    width: 51%;
    background: #fff;
}

.chevron:before {
    left: 0;
    transform: skew(0deg, 30deg);
}

.chevron:after {
    right: 0;
    width: 50%;
    transform: skew(0deg, -30deg);
}

@keyframes move {
    25% {
        opacity: 1;

    }
    33% {
        opacity: 1;
        transform: translateY(30px);
    }
    67% {
        opacity: 1;
        transform: translateY(40px);
    }
    100% {
        opacity: 0;
        transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
    }
}


/* Responsive Media Query */

@media screen and (max-width: 1080px) {
    .welcome-main{
        height: 100vh;
    }
}

@media screen and (max-width: 768px) {
    .welcome-main .riplay-logo{
        width: 300px;
    }
}
.contact-main{
    display: flex;
    justify-content: center;
}

.contact-content{
    padding: 50px 50px;
    display: flex;
    max-width: 1080px;
    width: 100%;
}

.contact-options{
    display: flex;
    flex-direction: column;
    color: #fff;
    border-right: solid 1px rgb(28, 28, 28);
    padding-right: 60px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.contact-options h2{
    font-size: 50px;
    text-transform: uppercase;
    color: #37BFE6;
    line-height: 60px;
}

.contact-options h2 span{
    font-size: 50px;
    font-weight: 700;
    font-family: "Oswald";
    color: #fff;
    display: block;
}

.contact-options > p{
    color: #b7b7b7;
    max-width: 300px;
    font-size: 16px;
    margin-top: 20px;
}

.contact-options-holder{
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contact-options-holder p{
    color: #fff;
    font-size: 16px;
    font-weight: 400;
}

.contact-options-holder p span{
    display: block;
    font-size: 12px;
    color: #b7b7b7;
    margin-top: 5px;
}

.contact-options-holder a{
    width: 220px;
    height: 40px;
    text-transform: uppercase;
    background: #37BFE6;
    font-size: 12px;
    font-weight: 900;
    border-radius: 10px;
    border: solid 1px #37BFE6;
    cursor: pointer;
    text-align: center;
    line-height: 40px;
    text-decoration: none;
    color: #000;
}

.contact-options-holder a.whatsapp{
    background: #25d366;
    border: solid 1px #25d366;
}

.contact-options-holder a.outline{
    background: #000;
    color: #37BFE6;
}

.contact-options-holder a:hover{
    text-decoration: underline;
}

.contact-form-holder{
    display: flex;
    flex-direction: column;
    color: #fff;
    padding-left: 60px;
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative;
}

.contact-form-holder p{
    color: #fff;
    font-size: 16px;
    font-weight: 400;
}

.contact-form-holder p span{
    display: block;
    font-size: 12px;
    color: #b7b7b7;
    margin-top: 5px;
}

.contact-form-holder form{
    margin-top: 40px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    position: relative;
}

.contact-form-holder form label{
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;
    font-weight: 500;
    width: 300px;
}

.contact-form-holder form label.subject{
    width: 620px;
}

.contact-form-holder form label.msg{
    width: 630px;
}

.contact-form-holder form input{
    background: transparent;
    border: solid 1px #2b2b2b;
    padding: 8px 20px;
    border-radius: 10px;
    color: #fff;
    font-size: 12px;
    background-color: #080808;
}

.contact-form-holder form textarea{
    background: transparent;
    border: solid 1px #2b2b2b;
    padding: 15px 20px;
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    height: 150px;
    background-color: #080808;
    resize: none;
}

.contact-form-holder form button{
    padding: 13px 50px;
    text-transform: uppercase;
    background: #37BFE6;
    font-size: 12px;
    font-weight: 900;
    border-radius: 10px;
    border: solid 1px #37BFE6;
    cursor: pointer;
}

.contact-form-holder form button:hover{
    background-color: #000;
    color: #37BFE6;
}

.contact-form-holder .email-success{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.contact-form-holder .email-success span{
    font-weight: 600;
}

/* Responsive Media Query */

@media screen and (max-width: 1080px) {
    .contact-content{
        flex-direction: column;
    }
    .contact-options{
        border-right: none;
        border-bottom: solid 1px rgb(28, 28, 28);
        padding-bottom: 50px;
    }
    .contact-form-holder{
        padding-left: 0;
    }
    .contact-form-holder form{
        flex-direction: column;
    }
    .contact-form-holder form label{
        width: 100%;
    }
    .contact-form-holder form label.subject{
        width: 100%;
    }
    .contact-form-holder form label.msg{
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    
}
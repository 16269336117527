.blog-feed{
    display: flex;
    flex-direction: column;
    align-items: center;
}


.blog-feed .blog-content{
    width: 100%;
    max-width: 1080px;
    display: flex;
    padding: 80px 30px 0px 30px;
    flex-direction: column;
    gap: 65px;
}

.blog-feed .blog-content .latest-posts{
    margin-top: 27px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.blog-feed .blog-content .latest-posts h1{
    font-size: 90px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    line-height: 100px;
}

.blog-feed .blog-content .latest-posts h1 span{
    font-family: "Oswald";
    font-weight: 600;
    color: #FF9E1B;
}

.blog-feed .blog-content .latest-posts ul{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.blog-feed .blog-content .latest-posts ul li{
    height: 297px;
    width: 240px;
    border: solid 1px rgba(255, 255, 255, 0.1);
    color: #fff;
    list-style: none;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
}

.blog-feed .blog-content .latest-posts ul li img{
    width: 100%;
    min-height: 300px;
    max-height: 300px;
}

.blog-feed .blog-content .latest-posts ul li:nth-child(1){
    width: 500px;
    height: 565px;
}

.blog-feed .blog-content .latest-posts ul li:nth-child(2){
    width: 500px;
    height: 565px;
}

.blog-feed .blog-content .latest-posts ul li .post-content-highlight{
    width: 100%;
    height: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.blog-feed .blog-content .latest-posts ul li .post-content-highlight h2{
    font-size: 30px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    line-height: 36px;
}

.blog-feed .blog-content .latest-posts ul li .post-content-highlight h2 span{
    text-transform: uppercase;
    font-size: 16px;
    font-family: "Oswald";
    font-weight: 500;
    color: #FF9E1B;
}

.blog-feed .blog-content .latest-posts ul li .post-content-highlight .post-author-date{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.blog-feed .blog-content .latest-posts ul li .post-content-highlight .post-author-date p{
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
}

.blog-feed .blog-content .latest-posts ul li .post-content-highlight .post-author-date p span{
    opacity: 0.5;
    font-size: 12px;
    font-weight: 300;
}

.blog-feed .blog-content .latest-posts ul li .post-content-highlight .post-author-date span{
    font-size: 12px;
    opacity: 0.5;
    font-weight: 400;
}

.blog-feed li .post-content-main{
    width: 100%;
    height: 100%;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.blog-feed li .post-content-main .title{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.blog-feed li .post-content-main .date{
    font-size: 12px;
    opacity: 0.5;
}

.blog-feed li .post-content-main h2{
    font-size: 23px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    line-height: 28px;
}

.blog-feed li .post-content-main h2 span{
    text-transform: uppercase;
    font-size: 14px;
    font-family: "Oswald";
    font-weight: 500;
    color: #FF9E1B;
}

.blog-feed li .post-content-main .author{
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
}

.blog-feed li .post-content-main .author span{
    font-size: 12px;
    opacity: 0.5;
}

.blog-feed .blog-content .latest-posts ul li .post-hover{
    width: 45px;
    height: 45px;
    background-color: #FF9E1B;
    position: absolute;
    right: 0;
    bottom: 0;
    display: none;
    align-items: center;
    justify-content: center;
}

.blog-feed .blog-content .latest-posts ul li .post-hover svg{
    position: relative;
    top: -2px;
    left: 2px;
}

.blog-feed .blog-content .latest-posts ul li:hover .post-hover{
    display: flex;
}

.blog-feed .blog-content .latest-posts .display-all-posts{
    width: 100%;
    height: 60px;
    background: none;
    border: solid 1px rgba(255, 255, 255, 0.1);
    font-size: 14px;
    color: #666;
    text-transform: uppercase;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.blog-feed .blog-content .latest-posts .display-all-posts:hover{
    color: #fff;
    border: solid 1px #fff;
}


@media screen and (max-width: 1080px) {
    .blog-feed .blog-content .latest-posts ul li:nth-child(1){
        width: 100%;
        height: auto;
    }
    .blog-feed .blog-content .latest-posts ul li:nth-child(1) .post-content-highlight{
        gap: 40px;
    }
    .blog-feed .blog-content .latest-posts ul li:nth-child(1) img{
        min-height: inherit;
        max-height: inherit;
        height: auto;
    }
    .blog-feed .blog-content .latest-posts ul li:nth-child(2){
        width: 100%;
        height: auto;
    }
    .blog-feed .blog-content .latest-posts ul li:nth-child(2) .post-content-highlight{
        gap: 40px;
    }
    .blog-feed .blog-content .latest-posts ul li:nth-child(2) img{
        min-height: inherit;
        max-height: inherit;
        height: auto;
    }
    .blog-feed .blog-content .latest-posts ul li{
        width: 100%;
    }
}
.our-locations-main{
    width: 100%;
    padding: 100px 0px;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.our-locations-main .our-locations-container{
    width: 100%;
    height: 600px;
    position: relative;
    background: url("../../assets/ourLocations/ourLocationsImg.png");
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    justify-content: right;
    padding: 0px 30px;
    flex-direction: column;
}

.our-locations-main .our-locations-container .our-locations-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: flex-end;
}

.our-locations-main .our-locations-container .our-locations-content h3{
    color: #fff;
    font-size: 70px;
    text-transform: uppercase;
    font-weight: 900;
    text-align: right;
    text-shadow: #000 0px 0px 20px;
    line-height: 70px;
    font-style: italic;
}

.our-locations-main .our-locations-container .our-locations-content ul{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 40px;
    margin-bottom: 40px;
}

.our-locations-main .our-locations-container .our-locations-content ul li{
    list-style: none;
    font-size: 20px;
    font-weight: 900;
    color: #fff;
    margin: 5px 0px;
    font-family: "Oswald";
    text-transform: uppercase;
    text-shadow: #000 0px 0px 20px;
}

.our-locations-main .our-locations-container .our-locations-content a{
    text-shadow: #000 0px 0px 20px;
}

/* Responsive Media Query */

@media screen and (max-width: 1080px) {

}

@media screen and (max-width: 768px) {
    .our-locations-main .our-locations-container{
        background-attachment: scroll;
    }
}
.google-maps-main{
    width: 100%;
    padding: 0px 0px;
}

.google-maps-main .map{
    width: 100%;
    height: 400px;
    position: relative;
}

.map-container{
    width: 100%;
    height: 100%;
}

/* Google Maps - Remove satellite button */
.gmnoprint{
    display: none;
}

/* Google Maps - Remove full screen button */
button.gm-control-active.gm-fullscreen-control{
    display: none;
}

/* Google Maps - Marker Name */
.marker-name{
    position: absolute;
    width: 100%;
    bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.marker-name span{
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    background-color: #F7A700;
    padding: 5px 15px;
    border-radius: 20px;
    border: solid 1px #000;
    box-shadow: rgb(100, 100, 100) 0px 0px 10px;
    animation-name: markerNameAnimation;
    animation-duration: 0.2s;
    position: relative;
}

@keyframes markerNameAnimation {
    from {bottom: -40px;}
    to {bottom: 0px;}
}

/* Unity Info Box */
.google-maps-main .unity-info-holder{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 6;
    top: 0;
    left: 0;
}

.google-maps-main .light-box{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.8;
}

.google-maps-main .unity-info-box{
    width: 100%;
    max-width: 1080px;
    position: fixed;
    padding: 0px 30px;
    animation-name: infoBoxAnimation;
    animation-duration: 0.4s;
}

@keyframes infoBoxAnimation {
    from {margin-top: 150px;}
    to {margin-top: 0px;}
}

.google-maps-main .unity-info-box-container{
    width: 100%;
    background-color: #000;
    border-radius: 10px;
    border: solid 1px #393939;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
}

.google-maps-main button.unity-info-box-close-btn{
    width: 20px;
    height: 20px;
    background-color: #000;
    background: url("../../assets/header/close-btn.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
}

.google-maps-main .unity-info-box-content{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    align-items: center;
}

.google-maps-main .unity-info-box-content .unity-images-slider{
    width: 360px;
    height: 250px;
    background-color: rgb(39, 39, 39);
}

.google-maps-main .unity-info-box-content .unity-images-slider .mySwiper{
    width: 360px;
    height: 250px;
}

.google-maps-main .unity-info-box-content .unity-images-slider .mySwiper img{
    width: 100%;
    height: 100%;
}

.google-maps-main .unity-info-box-content .unity-infos{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px 0px;
    color: #fff;
}

.google-maps-main .unity-info-box-content .unity-infos h3{
    font-size: 35px;
    text-transform: uppercase;
    line-height: 30px;
}

.google-maps-main .unity-info-box-content .unity-infos h3 span{
    font-size: 12px;
    text-transform: uppercase;
    display: block;
    font-weight: 600;
    color: #37BFE6;
    letter-spacing: 1px;
}

.google-maps-main .unity-info-box-content .unity-infos p{
    font-size: 14px;
    font-weight: 400;
}

.google-maps-main .unity-info-box-content .unidade-available-modalities{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.google-maps-main .unity-info-box-content .unidade-available-modalities .mod-available{
    color: #FF9E1B;
    border: solid 2px #FF9E1B;
    padding: 3px 10px;
    text-transform: uppercase;
    font-size: 10px;
    border-radius: 20px;
    font-weight: 800;
}

.google-maps-main .unity-info-box-content .unity-infos a{
    font-size: 12px;
    text-transform: uppercase;
    color: #000;
    background-color: #25d366;
    width: 200px;
    padding: 10px 0px;
    text-align: center;
    text-decoration: none;
    font-weight: 700;
    border-radius: 20px;
    border: solid 1px #25d366;
}

.google-maps-main .unity-info-box-content .unity-infos a:hover{
    background-color: #000;
    color: #25d366;
    border: solid 1px #25d366;
}

/* Unit Near You */
.unit-near-you{
    padding: 70px 30px;
    display: flex;
    justify-content: center;
}

.unit-near-you .allow-geolocation{
    display: flex;
    flex-direction: column;
    gap: 50px;
    max-width: 1080px;
    width: 100%;
}

.unit-near-you .allow-geolocation h2{
    font-size: 40px;
    color: #fff;
    line-height: 50px;
    text-transform: uppercase;
}

.unit-near-you .allow-geolocation h2 span{
    font-weight: 700;
    font-family: "Oswald";
    display: block;
    color: #37BFE6;
}

.unit-near-you .allow-geolocation .button-allow-holder{
    display: flex;
    gap: 10px;
    color: #fff;
    align-items: center;
}

.unit-near-you .allow-geolocation .allowAlertBox p{
    font-size: 14px;
    color: rgb(128, 128, 128);
    border-radius: 100px;
    font-weight: 400;
}

.unit-near-you .allow-geolocation .allowAlertBox p span{
    font-size: 12px;
    color: #fff;
    border-radius: 100px;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
}

.unit-near-you .allow-geolocation .button-allow-holder button{
    width: 300px;
    padding: 10px 30px;
    font-size: 12px;
    text-transform: uppercase;
    color: #37BFE6;
    background-color: #000;
    border: solid 1px #37BFE6;
    font-weight: 700;
    border-radius: 5px;
    cursor: pointer;
}

.unit-near-you .allow-geolocation .button-allow-holder button:hover{
    text-decoration: underline;
}

/* Unity Near You Found */
.unit-near-you-found{
    padding: 70px 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.unit-near-you-found .unit-near-holder{
    width: 100%;
    max-width: 1080px;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    align-items: center;
}

.unit-near-you-found .image-slider-near{
    width: 100%;
    height: 60vw;
    max-width: 450px;
    max-height: 300px;
    background-color: #393939;
}

.unit-near-you-found .image-slider-near .mySwiper{
    width: 100%;
    height: 100%;
}

.unit-near-you-found .image-slider-near .mySwiper img{
    width: 100%;
    height: 100%;
}

.unit-near-you-found .unity-found-info-box{
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #fff;
    flex-direction: column;
}

.unit-near-you-found .unity-found-info-box .unity-title h2{
    font-size: 35px;
    color: #fff;
    text-transform: uppercase;
}

.unit-near-you-found .unity-found-info-box .unity-title span{
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    color: #37BFE6;
}

.unit-near-you-found .unity-found-info-box .unity-distace{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
}

.unit-near-you-found .unity-found-info-box .unity-distace p{
    font-weight: 400;
    color: #999999;
}

.unit-near-you-found .unity-found-info-box .unity-address{
    font-size: 15px;
    font-weight: 400;
}

.unit-near-you-found .unity-found-info-box .unity-address span{
    font-size: 15px;
    font-weight: 400;
    display: block;
}

.unit-near-you-found .unity-found-info-box  .unidade-available-modalities{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.unit-near-you-found .unity-found-info-box  .unidade-available-modalities .mod-available{
    color: #FF9E1B;
    border: solid 2px #FF9E1B;
    padding: 3px 10px;
    text-transform: uppercase;
    font-size: 10px;
    border-radius: 20px;
    font-weight: 800;
}

.unit-near-you-found .unity-found-info-box a{
    font-size: 12px;
    text-transform: uppercase;
    color: #000;
    background-color: #25d366;
    width: 200px;
    padding: 10px 0px;
    text-align: center;
    text-decoration: none;
    font-weight: 700;
    border-radius: 20px;
    border: solid 1px #25d366;
    margin-top: 10px;
}

.unit-near-you-found .unity-found-info-box a:hover{
    background-color: #000;
    color: #25d366;
    border: solid 1px #25d366;
}

.top-holder-maps{
    min-height: 350px;
}

/* Responsive Media Query */
@media screen and (max-width: 1079px) {
    .google-maps-main .unity-info-box-content{
        flex-direction: column;
        padding-top: 40px;
    }
}

@media screen and (max-width: 768px) {
    .google-maps-main .unity-info-box-content .unity-images-slider{
        width: 100%;
        height: 40vw;
        background-color: rgb(39, 39, 39);
    }
    
    .google-maps-main .unity-info-box-content .unity-images-slider .mySwiper{
        width: 100%;
        height: 40vw;
    }
}



/*Loader*/
.loader-location-box{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.9;
}

.loader-location-box-content{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loader-location-box-content p{
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    margin-top: 20px;
}

.loader-location-box-content button{
    color: #37BFE6;
    font-size: 13px;
    font-weight: 700;
    background: none;
    margin-top: 3px;
    cursor: pointer;
}

.loader-location-box-content button:hover{
    text-decoration: underline;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid #37BFE6;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 25px;
      left: 25px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 25px;
      left: 25px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 25px;
      left: 25px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 50px;
      height: 50px;
      opacity: 0;
    }
  }
  
.main-header{
    width: 100%;
    display: flex;
    position: fixed;
    flex-direction: column;
    top: 0px;
    z-index: 5;
}

/* Orange Bar */

.main-header .orange-bar{
    width: 100%;
    height: 36px;
    background: #F7A700;
}

/* Header Bar */

.main-header .header-bar{
    width: 100%;
    height: 65px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 50px;
    position: relative;
}



.main-header .logo-header{
    width: 50px;
}

.main-header .nav-holder{
    display: flex;
    align-items: center;
    animation-name: headerBarAnimation;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
    position: relative;
    animation-fill-mode: forwards;
    right: -100px;
    opacity: 0;
}

@keyframes headerBarAnimation {
    from {right: -100px; opacity: 0;}
    to {right: 0px; opacity: 1;}
}

.main-header .nav-holder nav{
    display: flex;
}

.main-header .nav-holder nav > a{
    font-size: 12px;
    text-transform: uppercase;
    color: #727272;
    text-decoration: none;
    font-weight: 400;
    margin-right: 30px;
}

.main-header .nav-holder nav > a:hover{
    color: #F7A700;
}

.main-header .nav-holder nav > a:last-child{
    margin-right: 0px;
}

.main-header .nav-holder nav > a.active{
    position: relative;
}

.main-header .nav-holder nav > a.active::after{
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    bottom: -28px;
    height: 5px;
    background-color: #F7A700;
}

.main-header .nav-holder .schedule-btn{
    text-transform: uppercase;
    text-decoration: none;
    color: #F7A700;
    font-weight: 600;
    font-size: 12px;
    margin-left: 30px;
    padding: 10px 21px;
    border: solid 2px #F7A700;
    border-radius: 100px;
}

.main-header .nav-holder .schedule-btn:hover{
    background: #F7A700;
    color: #fff;
}

/* Mobile Menu */
.main-header .header-bar-mobile{
    width: 100%;
    display: none;
    justify-content: right;
}

.main-header .header-bar-mobile .btn-mobile-holder{
    width: 65px;
    height: 65px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.main-header .nav-holder-mobile{
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    right: 0;
    padding: 40px 20px;
    animation-name: open-nav-holder;
    animation-duration: 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes open-nav-holder {
    from {width: 20%; height: 0%;}
    to {width: 100%; height: 100%;}
}

.main-header .nav-holder-mobile .nav-holder-mobile-top-content{
    display: flex;
    align-items: center;
    justify-content: right;
}

.main-header .nav-holder-mobile button.btn-close-mobile-nav{
    width: 65px;
    height: 65px;
    background-color: #fff;
    background-image: url("../../assets/header/close-btn.png");
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
}

.main-header .nav-holder-mobile nav{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main-header .nav-holder-mobile nav a{
    padding: 15px 0px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    text-decoration: none;
    position: relative;
    animation-name: listnav;
    animation-duration: 1s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
    opacity: 0;
    letter-spacing: 2px;
}

@keyframes listnav {
    from {top: 40px; opacity: 0;}
    to {top: 0px; opacity: 1;}
}

.main-header .nav-holder-mobile nav .schedule-btn{
    color: #F7A700;
    border: solid 2px #F7A700;
    padding: 10px 20px;
    border-radius: 50px;
    margin-top: 30px;
}

/* Responsive Media Query */
@media screen and (max-width: 1080px) {
    .main-header .header-bar{
        display: none;
    }
    .main-header .header-bar-mobile{
        display: flex;
    } 
}
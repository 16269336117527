.partners-main{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 70px 30px;
}

.partners-main h4{
    color: #fff;
    font-size: 30px;
    margin-bottom: 40px;
    text-transform: uppercase;
}

.partners-main h4 span{
    font-weight: 700;
    font-family: "Oswald";
    color: #37BFE6;
}

.partners-main img{
    max-width: 900px;
    width: 100%;
}
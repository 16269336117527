

.post-simple-main{
    height: 297px;
    width: 240px;
    border: solid 1px rgba(255, 255, 255, 0.1);
    color: #fff;
    list-style: none;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
}

.post-simple-main .post-content{
    width: 100%;
    height: 100%;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.post-simple-main .post-content .title{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.post-simple-main .post-content .date{
    font-size: 12px;
    opacity: 0.5;
}

.post-simple-main .post-content h2{
    font-size: 23px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    line-height: 28px;
}

.post-simple-main .post-content h2 span{
    text-transform: uppercase;
    font-size: 14px;
    font-family: "Oswald";
    font-weight: 500;
    color: #FF9E1B;
}

.post-simple-main .post-content .author{
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
}

.post-simple-main .post-content .author span{
    font-size: 12px;
    opacity: 0.5;
}

.post-simple-main .post-hover{
    width: 45px;
    height: 45px;
    background-color: #FF9E1B;
    position: absolute;
    right: 0;
    bottom: 0;
    display: none;
    align-items: center;
    justify-content: center;
}

.post-simple-main .post-hover svg{
    position: relative;
    top: -2px;
    left: 2px;
}

.post-simple-main:hover .post-hover{
    display: flex;
}

.blog-nav-bar-main{
    width: 100%;
    height: 57px;
    background-color: #1313137a;
    position: fixed;
    top: 65px;
    left: 0;
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    z-index: 2;
    backdrop-filter: blur(20px);
}

.blog-nav-bar-main ul.nav-main-blog{
    display: flex;
    gap: 40px;
    width: 100%;
    max-width: 1080px;
    height: 100%;
    padding: 0px 30px;
    align-items: center;
}

.blog-nav-bar-main ul.nav-main-blog li{
    color: #fff;
    text-decoration: none;
    list-style: none;
    text-transform: uppercase;
    font-size: 16px;
    font-family: "Oswald";
    font-weight: 400;
}

.blog-nav-bar-main ul.nav-main-blog li a{
    color: #fff;
    text-decoration: none;
    font-family: "Oswald";
    font-weight: 400;
    height: 100%;
}

.blog-nav-bar-main ul.nav-main-blog li a:hover{
    color: #ff9e1b;
}

.blog-nav-bar-main ul.nav-main-blog li a.search{
    display: flex;
    align-self: center;
    justify-content: center;
}

.blog-nav-bar-main  ul.nav-main-blog li a.search:hover line{
    stroke: #ff9e1b;
}

.blog-nav-bar-main ul.nav-main-blog li a.search:hover g{
    stroke: #ff9e1b;
}




.blog-nav-bar-main .nav-main-blog.nav-mobile{
    display: none;
}

.blog-nav-bar-main .nav-main-blog.nav-mobile .category-nav-option{
    display: flex;
    align-items: center;
    gap: 20px;
}

.blog-nav-bar-main .nav-main-blog.nav-mobile li{
    cursor: pointer;
}

.blog-nav-bar-main .nav-main-blog.nav-mobile li:hover{
    color: #ff9e1b;
}




.blog-nav-bar-main .modal-mobile-nav{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.93);
    position: fixed;
    left: 0;
    z-index: 6;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

.blog-nav-bar-main .modal-mobile-nav button{
    width: 65px;
    height: 65px;
    position: absolute;
    right: 25px;
    top: 0;
    background-image: url("../../assets/header/close-btn.png");
    background-repeat: no-repeat;
    background-position: center;
    background-color: #333;
    cursor: pointer;
}

.blog-nav-bar-main .modal-mobile-nav h2{
    color: #fff;
    text-transform: uppercase;
    font-size: 25px;
    margin-top: 70px;
    font-weight: 400;
}

.blog-nav-bar-main .modal-mobile-nav h2 span{
    color: #ff9e1b;
    text-transform: uppercase;
    font-size: 25px;
    font-family: "oswald";
    margin-top: 70px;
    font-weight: 600;
}

.blog-nav-bar-main .modal-mobile-nav ul{
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
}

.blog-nav-bar-main .modal-mobile-nav ul li{
    list-style: none;
}

.blog-nav-bar-main .modal-mobile-nav ul li a{
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    font-family: "Oswald";
    font-size: 25px;
    font-weight: 400;
}

.blog-nav-bar-main .modal-mobile-nav ul li:hover a {
    color: #ff9e1b;
}

/* Responsive Media Query */
@media screen and (max-width: 1080px) {
    .blog-nav-bar-main{
        top: 0;
        z-index: 5;
        height: 65px;
        width: calc(100% - 65px);
    }
    .nav-main-blog.nav-desktop{
        display: none !important;
    }

    .blog-nav-bar-main .nav-main-blog.nav-mobile{
        display: flex;
    }
}
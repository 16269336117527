.blog-search-main{
    min-height: 500px;
    display: flex;
    justify-content: center;
}

.blog-search-main .blog-search-wrapper{
    width: 100%;
    max-width: 1080px;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 0px 30px;
}

.blog-search-main .blog-search-wrapper .search-bar{
    width: 100%;
    height: 50px;
    border: solid 2px rgba(255, 255, 255, 0.1);
    display: flex;
    gap: 20px;
}

.blog-search-main .blog-search-wrapper .search-bar input{
    height: 100%;
    border: none;
    width: 100%;
    background: none;
    color: #999;
    font-size: 16px;
    padding-left: 30px;
}

.blog-search-main .blog-search-wrapper .search-bar button{
    width: 120px;
    text-transform: uppercase;
    font-weight: 600;
    background: none;
    height: 100%;
    color: #fff;
    font-size: 14px;
    border-left: solid 2px rgba(255, 255, 255, 0.1);
}

.blog-search-main .blog-search-wrapper > h2{
    color: #fff;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 500;
    color: #FF9E1B;
}

.blog-search-main .blog-search-wrapper > h2 span{
    font-family: "Oswald";
    font-weight: 400;
    text-transform: uppercase;
    color: #fff;
}

.blog-search-main .blog-search-wrapper ul{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.blog-search-main .blog-search-wrapper ul li{
    list-style: none;
}

.blog-search-main .blog-search-wrapper ul .not-found-msg{
    color: #fff;
    font-weight: 400;
    font-size: 16px;
}

.blog-search-main .blog-search-wrapper ul .not-found-msg span{
    color: #FF9E1B;
    font-weight: 600;
    font-size: 16px;
}

/* Responsive Media Query */
@media screen and (max-width: 1080px) {
    .blog-search-main .blog-search-wrapper ul{
        justify-content: center;
    }
}
.admpanel-authors-main{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 60px;
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    position: relative;
}

.admpanel-authors-main ul{
    width: 100%;
    max-width: 1080px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.admpanel-authors-main ul li{
    list-style: none;
    width: 100%;
    background-color: transparent;
    border: solid 1px rgba(255, 255, 255, 0.1);
    display: flex;
    padding: 20px 20px 30px 20px;
    display: flex;
    gap: 20px;
    position: relative;
}

.admpanel-authors-main ul li .image{
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.admpanel-authors-main ul li .image img{
    width: 50px;
    max-height: 50px;
    border-radius: 200px;
}

.admpanel-authors-main ul li .content{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.admpanel-authors-main ul li span{
    font-size: 14px;
    font-weight: 500;
    height: 50px;
    display: flex;
    align-items: center;
}

.admpanel-authors-main ul li p{
    font-size: 14px;
    font-weight: 400;
    color: #999;
    line-height: 20px;
    padding-right: 40px;
}

.admpanel-authors-main ul li:hover button{
    opacity: 1;
}

.admpanel-authors-main ul li button{
    position: absolute;
    opacity: 0.2;
    right: 20px;
    top: 20px;
    padding: 5px 15px;
    border: solid 1px #fff;
    background-color: transparent;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
}

.admpanel-authors-main ul li button:hover{
    background-color: #fff;
    color: #000;
}

.admpanel-authors-main .button-holder{
    width: 100%;
    max-width: 1080px;
    display: flex;
    justify-content: flex-end;
}

.admpanel-authors-main .button-holder button{
    width: 255px;
    height: 49px;
    background-color: #fff;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

/* Modal Confirm */
.admpanel-authors-main .modal-confirm{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(15px);
}

.admpanel-authors-main .modal-confirm .modal-confirm-content{
    width: 300px;
    padding: 40px 30px;
    background-color: #000;
    border: solid 1px rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.admpanel-authors-main .modal-confirm .modal-confirm-content h2{
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.admpanel-authors-main .modal-confirm .modal-confirm-content h2 span{
    color: rgb(215, 70, 70);
    font-weight: 500;
    text-transform: none;
    font-size: 16px;
}

.admpanel-authors-main .modal-confirm .modal-confirm-content .buttons{
    display: flex;
    justify-content: center;
    gap: 20px;
}

.admpanel-authors-main .modal-confirm .modal-confirm-content .buttons button{
    padding: 8px 20px;
    background: transparent;
    color: #fff;
    border: solid 1px #fff;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s;
}

.admpanel-authors-main .modal-confirm .modal-confirm-content .buttons button:hover{
    background-color: rgba(255, 255, 255, 0.1);
}

.admpanel-authors-main .modal-confirm .modal-confirm-content .buttons button:nth-child(2){
    border: solid 1px rgb(215, 70, 70);
    font-weight: 500;
    color: rgb(215, 70, 70);
}
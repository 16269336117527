.beriplay-main{
    width: 100%;
    height: 1000px;
    background: url("../../assets/beriplay/52108313728_b1e46c4825_o.png");
    background-position: center;
    background-size: cover;
    padding: 80px 30px;
}

.beriplay-main .beriplay-content h3{
    color: #37BFE6;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 110px;
    line-height: 110px;
    margin-bottom: 80px;
    font-style: italic;
}

.beriplay-main .beriplay-content h3 span{
    color: #fff;
    font-weight: 900;
    font-family: "Oswald";
}

/* Responsive Media Query */

@media screen and (max-width: 768px) {
    .beriplay-main{
        height: 600px;
        background-position: center;
    }

    .beriplay-main .beriplay-content h3{
        font-size: 60px;
        line-height: 60px;
        margin-bottom: 50px;
    }
}
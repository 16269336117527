.admpanel-main{
    color: #fff;
    width: 100%;
    z-index: 10;
    background-color: #000;
    top: 0;
    min-height: 1000px;
}

.admpanel-main .panel-top-bar{
    width: 100%;
}

.admpanel-main .panel-top-bar .title-bar{
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

.admpanel-main .panel-top-bar .title-bar .title{
    font-size: 16px;
    font-weight: 500;
}

.admpanel-main .panel-top-bar .title-bar .welcome{
    font-size: 16px;
    font-weight: 300;
    color: #999;
}

.admpanel-main .panel-top-bar .title-bar .welcome span{
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}

.admpanel-main .panel-top-bar .panel-navbar{
    display: flex;
    justify-content: space-between;
    height: 35px;
    padding: 0px 30px;
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

.admpanel-main .panel-top-bar .panel-navbar ul{
    display: flex;
    gap: 30px;
    height: 100%;
}

.admpanel-main .panel-top-bar .panel-navbar ul li{
    list-style: none;
    font-size: 14px;
    font-weight: 400;
    color: #999;
    position: relative;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    line-height: 20px;
}

.admpanel-main .panel-top-bar .panel-navbar ul li:hover{
    color: #fff;
}

.admpanel-main .panel-top-bar .panel-navbar ul li.active{
    color: #fff;
}

.admpanel-main .panel-top-bar .panel-navbar ul li.active::after{
    position: absolute;
    content: "";
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    background-color: #fff;
}

.admpanel-main .panel-top-bar .panel-navbar span{
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: rgb(206, 36, 36);
    cursor: pointer;
}

.admpanel-main .panel-top-bar .panel-navbar span:hover{
    text-decoration: underline;
}
.posts-section-main{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.posts-section-main h2.section-title{
    font-size: 90px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    display: flex;
    gap: 20px;
}

.posts-section-main h2.section-title .section{
    font-family: "Oswald";
    font-weight: 600;
    color: #FF9E1B;
}

.posts-section-main h2.section-title .caption{
    font-family: "Oswald";
    font-weight: 600;
    color: #fff;
    font-size: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 40px;
}

.posts-section-main .posts-section-holder{
    width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.posts-section-main .posts-section-holder .post-section-holder-left{
    width: 240px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.posts-section-main .posts-section-holder .post-section-holder-right{
    width: 240px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.posts-section-main .posts-section-holder .post-section-middle-post{
    width: 500px;
    height: 614px;
    border: solid 1px rgba(255, 255, 255, 0.1);
    color: #fff;
    list-style: none;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
}

.posts-section-main .posts-section-holder .post-section-middle-post img{
    width: 100%;
    min-height: 300px;
    max-height: 300px;
}

.posts-section-main .posts-section-holder .post-section-middle-post .post-content-highlight{
    width: 100%;
    height: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.posts-section-main .posts-section-holder .post-section-middle-post .post-content-highlight h2{
    font-size: 30px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    line-height: 36px;
    align-items: center;
}

.posts-section-main .posts-section-holder .post-section-middle-post .post-content-highlight h2 span{
    text-transform: uppercase;
    font-size: 16px;
    font-family: "Oswald";
    font-weight: 500;
    color: #FF9E1B;
}

.posts-section-main .posts-section-holder .post-section-middle-post .post-content-highlight .post-author-date{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.posts-section-main .posts-section-holder .post-section-middle-post .post-content-highlight .post-author-date p{
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
}

.posts-section-main .posts-section-holder .post-section-middle-post .post-content-highlight .post-author-date p span{
    opacity: 0.5;
    font-size: 12px;
    font-weight: 300;
}

.posts-section-main .posts-section-holder .post-section-middle-post .post-content-highlight .post-author-date span{
    font-size: 12px;
    opacity: 0.5;
    font-weight: 400;
}

.posts-section-main .posts-section-holder .post-section-middle-post .post-hover{
    width: 45px;
    height: 45px;
    background-color: #FF9E1B;
    position: absolute;
    right: 0;
    bottom: 0;
    display: none;
    align-items: center;
    justify-content: center;
}

.posts-section-main .posts-section-holder .post-section-middle-post .post-hover svg{
    position: relative;
    top: -2px;
    left: 2px;
}

.posts-section-main .posts-section-holder .post-section-middle-post:hover .post-hover{
    display: flex;
}

.posts-section-main .display-all-posts{
    width: 100%;
    height: 60px;
    background: none;
    border: solid 1px rgba(255, 255, 255, 0.1);
    font-size: 14px;
    color: #666;
    text-transform: uppercase;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.posts-section-main .display-all-posts:hover{
    color: #fff;
    border: solid 1px #fff;
}

@media screen and (max-width: 1080px) {
    .posts-section-main{
        display: none;
    }
}